import { GTMAdBannerClickTracker } from '../[lng]/components/GTMTrackers/GTMAdBannerTracker'
import { AdBannerScheduleType } from '../types/adType'

// Get the indices of the ads according to the target positon
export const getAdsIndex = (
  ads: AdBannerScheduleType[],
  targetPosition: string
) => {
  return ads
    ?.map((item, index) =>
      targetPosition === item?.ad_position.name ? index : -1
    )
    .filter((index) => index !== -1)[0]
}
export const getAdsIndices = (
  ads: AdBannerScheduleType[],
  targetPosition: string
) => {
  return ads
    .map((item, index) =>
      targetPosition === item.ad_position.name ? index : -1
    )
    .filter((index) => index !== -1)
}

export const handleAdClick = (
  adBanner: AdBannerScheduleType,
  ad_lang: string
) => {
  const url = adBanner?.ad_banner
  GTMAdBannerClickTracker({
    ad_clicked_id: String(adBanner?.ad_banner?.id),
    ad_schedule_id: adBanner?.id,
    ad_position: adBanner?.ad_position?.name,
    ad_lang: ad_lang,
    ad_page: adBanner?.page?.name,
    ad_company_id: adBanner?.ad_banner?.company?.id,
  })
  let redirect_url = url.redirect_url.trim()
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  if (
    !redirect_url?.startsWith('mailto:') &&
    !redirect_url?.startsWith('http://') &&
    !redirect_url?.startsWith('https://')
  ) {
    if (emailRegex.test(redirect_url)) {
      redirect_url = `mailto:${redirect_url}`
    } else {
      redirect_url = `https://${redirect_url}`
    }
  }
  window.open(redirect_url, '_blank')
}
