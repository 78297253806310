'use client'
import { usePathname, useRouter } from 'next/navigation'

export const useNavigation = () => {
  const router = useRouter()
  const pathname = usePathname()
  const lang = pathname.split('/')[1] || 'en'
  const redirectToAdvertise = () => {
    router.push(`/${lang}/advertise_page/website_ad/ad_home_page`)
  }

  return { redirectToAdvertise }
}
