import React from 'react'
import { Tooltip } from '@mui/material'
import { useTranslation } from '@/app/i18n/client'
import { Origin } from '@/app/types/homeSalesOfferResponseType'

const OriginsDisplay = ({
  origins,
  count,
}: {
  origins: Origin[]
  count: number
}) => {
  const { t } = useTranslation()

  return (
    <span className="font-ns_bold flex">
      {t('Origins')}:
      <span className="font-light flex ml-1">
        {origins?.length > 0 ? (
          <>
            {origins
              .slice(0, count)
              .map((item) => item.name)
              .join(', ')}
            {origins.length > count && (
              <Tooltip
                title={origins
                  .slice(count)
                  .map((item) => item.name)
                  .join(', ')}
                arrow
              >
                <span className="cursor-pointer ml-1">{` +${
                  origins.length - count
                }`}</span>
              </Tooltip>
            )}
          </>
        ) : (
          t('NA')
        )}
      </span>
    </span>
  )
}

export default OriginsDisplay
